.companies {
  &__upper {
    display: flex;
    justify-content: space-between;
    &__searchbar {
      width: 25vw;
      border: none;
    }
  }
}




@hack: true; @import "./src/styles/theme.less";
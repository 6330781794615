.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: gray;
  padding: 1px;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #00519d;
  padding: 1px;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #00519d;
}
.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #00519d;
}

.ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-weight: bold;
}

.ant-steps .ant-steps-item-tail {
  top: 19px;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  margin-inline-start: 72px;
}

.ant-steps
  .ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  color: #00519d;
}

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';




@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.group:hover .appIconImg {
  /* background: white; */
  filter: brightness(0) saturate(100%) invert(13%) sepia(98%) saturate(3565%)
    hue-rotate(197deg) brightness(98%) contrast(102%);
  /* background-blend-mode: color-multiply; */
}

.iconContainer {
  background-blend-mode: multiply;
}

/* .progress-wrapper {
  width: 50px !important; 
  height: 50px !important; 
}

.ant-progress-inner{
  width: 50px !important; 
  height: 50px !important; 

} */

.green-text.ant-progress .ant-progress-text {
  color: #00AC4F;
  /* color: #00519d; */
  font-weight: 500;
  text-align: center;
}
.red-text.ant-progress .ant-progress-text {
  color: #DA001A;
  /* color: #00519d; */
  font-weight: 500;
  text-align: center;
}
.blue-text.ant-progress .ant-progress-text {
  color: #00519d;
  /* color: #00519d; */
  font-weight: 500;
  text-align: center;
}
